import React from 'react';
import ListComponent from '..';
import ListItemLabAssign from '../Item/ListItemLabAssign';

const ListAssignLaboratory = ({list,handleAction}) => {
    return (
        <ListComponent mini={false} handleAction={handleAction} empty={'laboratorios'} titles={['Laboratorio','Asignado']} list={list} ListComponent={ListItemLabAssign}  />
    );
};

export default ListAssignLaboratory;