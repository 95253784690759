import React, { Component } from 'react';
import { connect } from 'react-redux';
import { reduxForm, getFormValues } from 'redux-form';
import { Prompt , withRouter} from 'react-router-dom';
import PropTypes from 'prop-types';
import { Row, Col } from 'react-flexbox-grid';
import { questionInputabilityEvaluation} from '../../../../../constants/steps';
import { FieldSelect, FieldCheck, FieldText } from '../../../../Fields';
import '../../../style.css';
import { Card } from 'semantic-ui-react';
import {Button} from "@material-ui/core";
import Signature from '../../../../Signature';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Popover, PopoverHeader, PopoverBody } from 'reactstrap';
import { faCommentAlt } from '@fortawesome/free-regular-svg-icons';
import { url_postImputEvaluation, url_getActionsERByMeta } from '../../../../../api/urls';
import { updateERImputEvaluation, setERActions } from '../../../../../redux/actions/eventoReportable';
import { setLoad } from '../../../../../redux/actions/context';
import { showError } from '../../../../../redux/actions/error'
import AppLoader from '../../../../AppComponents/AppLoader';
import api from '../../../../../api';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';

class ImputabilityEvaluation extends Component {

    state = {
        hideFabricante:true,
        status:"",
        openSiganture:false,
        total:0,
        popoverOpen:'',
        success:false,
        loading:false,
    };

    componentDidMount = () => {
        this.handleCalculator();
    }

    componentDidUpdate = (prevProps, prevState) =>{
        this.handleCalculator();
    }

    handleCalculator = () =>{
        const values = this.props.formValules;
        let text = "";
        let sum= 0;
        if(values)
            questionInputabilityEvaluation.forEach((q, i) => {
                sum += Number(q.answer.find(a => Number(a.index) === Number(values[`r${i+1}`])).value);
            });
        if (sum >= 9)
            text ="Segura";
        else if (sum >= 5 && sum <= 8)
            text ="Probable";
        else if (sum >= 1 && sum <= 4)
            text ="Posible";
        else if (sum <= 0)
            text ="Improbable";
        if(this.state.status !== text || this.state.total !== sum){
            this.props.change('Total',sum);
            this.props.change('Estado',text);
            this.setState({ status:text,total:sum });
        }

    }

    handleChangeEvaluetion = (e) =>{
        if(this.props.UserContext.Rol==="QPPV"){
            if(e.target.value === this.props.initialValues[`${e.target.name}`] || e.target.value === 0){
                this.props.change(e.target.name, this.props.initialValues[e.target.name]);
                this.props.change(e.target.name+'LeidaQppv', this.props.initialValues[`${e.target.name}LeidaQppv`]);
                this.props.change(e.target.name+'RevisadaQppv', this.props.initialValues[`${e.target.name}Revi sadaQppv`]);
                this.props.change(e.target.name+'ComentarioQppv', this.props.initialValues[`${e.target.name}ComentarioQppv`]);
                this.props.change(e.target.name+'changeQppv', false);
            }
            else{
                this.props.change(e.target.name+'LeidaQppv', true);
                this.props.change(e.target.name+'RevisadaQppv', true);
                this.props.change(e.target.name+'ComentarioQppv', '');
                this.props.change(e.target.name+'changeQppv', true);
            }
        }
    }

    saveAvaluation = async (comentario) =>{

      try{
        this.togglepopoverOpen();
        this.setState({ loading:true });
        this.props.setLoad(true);
        const encuesta = this.props.formValules;
        const user = this.props.UserContext.Id
        const er = this.props.er
        const questions = questionInputabilityEvaluation.map(x => x.label);
        const inputEvaluation = await api.post(url_postImputEvaluation, { encuesta, comentario, user, er, questions });
        const actions = await api.get(url_getActionsERByMeta(this.props.meta));
        this.props.setERActions(actions.data);
        this.props.updateERImputEvaluation(inputEvaluation.data);
        this.props.closeEvaluation();

      }catch(error){
        this.props.showError(error);
      }finally{
        this.props.setLoad(false);
        this.setState({ success: true, loading: false });
      }
    }

    handleValidateAnswer = (rol,index) =>{
        switch(rol){
            case "RLF":{
                return (this.props.formValules[`r${index}`] > 0);
            }
            case "QPPV":{
                const revisada =this.props.formValules[`r${index}RevisadaQppv`];
                if(revisada)
                {
                    return  (this.props.formValules[`r${index}`] > 0 && this.props.formValules[`r${index}ComentarioQppv`].length > 0);
                }else{
                    return  (this.props.formValules[`r${index}`] > 0 && this.props.formValules[`r${index}LeidaQppv`] );
                }

            }
            case "Fabricante":{
                return true;
            }
            default:return false;
        }

    }

    handleValidate = () => {
        let valid = true;
        let i = 1;
        while(i<=10 && valid){
            valid = this.handleValidateAnswer(this.props.UserContext.Rol,i);
            i++;
        }
        this.setState({openSiganture:valid});
    }

    handleSignature = () =>  this.setState({openSiganture:! this.state.openSiganture});

    togglepopoverOpen = () => this.setState({popoverOpen:''});

    disabledSelect = (name) =>  this.props.formValules && (this.props.UserContext.Rol ==='RLF' && (this.props.formValules[`${name}RevisadaQppv`] ||  this.props.formValules[`${name}LeidaQppv`]));


    render(){
        const { status, total, hideFabricante, openSiganture,loading } = this.state;
        const { UserContext,formValules } = this.props;
        return (
            <div>
               { loading && <AppLoader size={150}/> }
               <Card.Group itemsPerRow={1} className="m-b-md">
                <Card>
                    <Card.Content header="Evaluación de imputabilidad"/>
                    <Card.Content>
                        <Row className='m-r-md-m m-l-md-m'>
                            <Col className="title-column-evaluation" md={hideFabricante? 8:6} sm={hideFabricante? 5:3} xs={12}>PREGUNTA</Col>
                            <Col className="title-column-evaluation" sm={2} xs={12}>RESPUESTA</Col>
                            <Col hidden={hideFabricante} className="title-column-evaluation" sm={2} xs={12}>FABRICANTE</Col>
                            <Col className="title-column-evaluation" sm={2} xs={12}>QPPV</Col>
                        </Row>
                        {questionInputabilityEvaluation.map((q, i) =>(
                            <Row key={i}  className="m-b-sm">
                                <Col  md={hideFabricante? 8:6} sm={hideFabricante? 5:3} xs={12}>
                                    {
                                        <span className="va-m">{`${i+1}) ${q.label}`}</span>
                                    }
                                </Col>
                                <Col md={2} sm={3} xs={12}>
                                    <FieldSelect onChange={this.handleChangeEvaluetion} withOutSelectDefault={true} disabled={this.disabledSelect(q.name)}  inline={true} name={q.name} items={q.answer} typeValue="text" textField="label" valueField="index"/>
                                </Col>

                                <Col hidden={hideFabricante} sm={2} xs={12}>
                                { formValules && !formValules[`${q.name}RevisadaFabricante`] && <FieldCheck disabled={UserContext.Rol!=="Fabricante"} inline={true} name={`${q.name}LeidaFabricante`} label="Leída Fabricante"/>}
                                { formValules && formValules[`${q.name}RevisadaFabricante`] && <FieldCheck disabled={UserContext.Rol!=="Fabricante"} inline={true} name={`${q.name}RevisadaFabricante`} label="Revisada Fabricante"/>}
                                { formValules && formValules[`${q.name}RevisadaFabricante`] &&  <FieldText disabled={UserContext.Rol!=="Fabricante"} type="textarea" label="Comentario Fabricante" isRequired={true} name={`${q.name}ComentarioFabricante`}/>  }
                                </Col>
                                <Col sm={2} xs={12}>
                                    { formValules && !formValules[`${q.name}RevisadaQppv`] && <FieldCheck disabled={UserContext.Rol!=="QPPV"} inline={true} name={`${q.name}LeidaQppv`} label="Leída QPPV"/>}
                                    { formValules && formValules[`${q.name}RevisadaQppv`] && <FieldCheck disabled={UserContext.Rol!=="QPPV"} inline={true} name={`${q.name}RevisadaQppv`} label="Revisada QPPV"/>}
                                    { formValules && formValules[`${q.name}ComentarioQppv`] !== '' &&
                                        <FontAwesomeIcon className='m-l-md' id={`PO_${q.name}`} icon={faCommentAlt} size={'lg'} onClick={()=> this.setState({popoverOpen : `${q.name}RevisadaFabricante`})}/> }
                                    { this.state.popoverOpen === `${q.name}RevisadaFabricante` &&
                                        <Popover placement="bottom" isOpen={this.state.popoverOpen === `${q.name}RevisadaFabricante`} target={`PO_${q.name}`} toggle={this.togglepopoverOpen}>
                                            <PopoverHeader>Comentario de QPPV</PopoverHeader>
                                            <PopoverBody>{formValules[`${q.name}ComentarioQppv`]}</PopoverBody>
                                        </Popover>
                                    }
                                </Col>
                                { formValules && formValules[`${q.name}RevisadaQppv`] && formValules[`${q.name}changeQppv`] && UserContext.Rol==="QPPV" &&
                                    <Col  mdOffset={hideFabricante ? 8:6} smOffset={hideFabricante? 5:3} sm={hideFabricante? 4:2} xs={12}>
                                        <FieldText  type="textarea" label="Comentario QPPV" isRequired={true} name={`${q.name}ComentarioQppv`}/>
                                    </Col>
                                }

                            </Row>
                            ))}
                    </Card.Content>
                    <Card.Content extra className="card-actions">
                        <h3 className="va-m pull-left">Total: {total} {status}</h3>
                        <Button type="button"  disabled={this.state.loading} className="pull-right card-actions-button button-next" variant="contained" onClick={this.handleValidate}>
                            Guardar{" "}
                            {this.state.loading && (
                            <FontAwesomeIcon
                                className="m-l-sm"
                                size="lg"
                                icon={faSpinner}
                                pulse={this.state.loading}
                            />
                            )}
                        </Button>
                    </Card.Content>
                </Card>
            </Card.Group>
            <Signature title="Evalular imputabilidad"
                            message="¿Está seguro qué quiere evaluar la imputabilidad de la RAM?"
                            open={openSiganture}
                            toggle={this.handleSignature}
                            handleConfirm={this.saveAvaluation}/>
            <Prompt when={!this.props.pristine && !this.state.success} message={"¡Se perderán los cambios en la evaluación de imputabilidad sí continúa!"}/>
            </div>
        );
    }
};

const validate = values =>{
    const error ={};

    return error;
}


ImputabilityEvaluation.propTypes = {
    initialValues:  PropTypes.object,
};


const mapDispatchToProps = {
    updateERImputEvaluation,
    setLoad,
    setERActions,
    showError

};

const mapStateToProps = state =>({
    formValules: getFormValues('ImputabilityEvaluation')(state)
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(reduxForm({form: 'ImputabilityEvaluation', validate})(ImputabilityEvaluation)));
