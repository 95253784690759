import React, { useState } from "react";

import { getElementCodiguera } from "../../../../codigueras";
import { Row, Col, Grid } from "react-flexbox-grid";
import { NavLink } from "react-router-dom";
import { FieldLabel } from "../../../Fields";
import SubGroupComponent from "../../../SubGroup";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes, faPen } from "@fortawesome/free-solid-svg-icons";

const SummaryStep1 = ({ values, codiguera, onChangeSubGroup, edit }) => {
  const [change, setchange] = useState(false);
  const pais = codiguera
    ? getElementCodiguera("pais", values.Pais, codiguera)
    : "---";
  const tipo = codiguera
    ? getElementCodiguera("subGroup", values.SubGroup, codiguera)
    : "---";
  return (
    <div className="form-step">
      <h3>
        Tipo del reporte{" "}
        {edit && (
          <NavLink to="#" onClick={() => setchange(!change)}>
            {" "}
            <FontAwesomeIcon
              color="#fff"
              className="float-right"
              icon={change ? faTimes : faPen}
            />
          </NavLink>
        )}
      </h3>
      <Row className="form-step-row">
        <Col sm={12} md={4}>
          <FieldLabel type="text" label="País" value={pais} />
        </Col>
        <Col sm={12} md={8}>
          <FieldLabel type="text" label="Tipo de acontecimiento" value={tipo} />
        </Col>
      </Row>
      <Grid hidden={!change}>
        <h6>Modificar el tipo de acontecimiento</h6>

        <SubGroupComponent
          selected={values.SubGroup}
          subGroups={codiguera.SubGrupo}
          onSelect={onChangeSubGroup}
        />
      </Grid>
    </div>
  );
};

export default SummaryStep1;
