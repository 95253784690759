import React from "react";
import PropTypes from "prop-types";
import { faPencilAlt, faCertificate } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import ListActions from "./ListActions";
import { faEye } from "@fortawesome/free-regular-svg-icons";

const ListItemRAM = ({ Item, classProp, handleAction, View, codiguera }) => {
  const actions = (handleAction, Item, View) =>
    Item.IsRAM
      ? [
          <Link to="#" onClick={() => handleAction(Item.Id)}>
            <FontAwesomeIcon
              icon={View === "edit" ? faPencilAlt : faEye}
              size={"lg"}
            />
          </Link>,
          /*<Link to="#" onClick={() => handleAction(Item.Id)}>
            <FontAwesomeIcon icon={faCertificate} size={"lg"} />
          </Link>*/
        ]
      : [
          <Link to="#" onClick={() => handleAction(Item.Id)}>
            <FontAwesomeIcon
              icon={View === "edit" ? faPencilAlt : faEye}
              size={"lg"}
            />
          </Link>
        ];
  if (View === "edit") {
    return (
      <tr className={`list-item ${classProp}`}>
        <td>{Item.MedraLLT ? Item.MedraLLT.llt_name : "---"}</td>
        <td>{Item.Description}</td>
        <td>{Item.IsRAM === null ? "---" : Item.IsRAM ? "SI" : "NO"}</td>
        <td>
          {Item.IsExpected === null ? "---" : Item.IsExpected ? "SI" : "NO"}
        </td>
        <td>{Item.TypeRAM ? Item.TypeRAM.Type : "---"}</td>
        <td>
          {
          Item.CategoryRAM !== null
            ? Item.CategoryRAM.Type
            : "---"}
        </td>
        <td className="p-t-sm">
          <ListActions actions={actions(handleAction, Item, View)} />
        </td>
      </tr>
    );
  } else {
    return (
      <tr className={`list-item ${classProp}`}>
        <td>{Item.MedraLLT ? Item.MedraLLT.llt_name : "---"}</td>
        <td>{Item.Description}</td>
        <td>{Item.IsRAM ? "SI" : "NO"}</td>
        <td>{Item.TypeRAM ? Item.TypeRAM.Type : "---"}</td>
        <td>
          {Item.CategoryRAM !== null && codiguera
            ? Item.CategoryRAM.Type
            : "---"}
        </td>
        <td>{Item.LocalSanitaryNotificaction ? "SI" : "NO"}</td>
        <td>{Item.ProviderNotification ? "SI" : "NO"}</td>
        <td className="p-t-sm">
          <ListActions actions={actions(handleAction, Item, View)} />
        </td>
      </tr>
    );
  }
};

ListItemRAM.propTypes = {
  Item: PropTypes.object,
  classProp: PropTypes.string,
  handleAction: PropTypes.func,
  View: PropTypes.string
};

export default ListItemRAM;
