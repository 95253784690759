import React from 'react';
import ListComponent from '..';
import ListItemAssignLab from '../Item/ListItemAssignLab';

const ListAssignLaboratories = ({list}) => {
    return (
        <ListComponent mini={false} empty={'laboratorios'} titles={['País','Compañía','Laboratorio']} list={list} ListComponent={ListItemAssignLab}  />
    );
};

export default ListAssignLaboratories;