import React, { Component } from 'react';
import { connect } from 'react-redux';
import { reduxForm, formValueSelector } from 'redux-form';
import { Grid, Row, Col } from 'react-flexbox-grid';
import { FieldSelect, FieldSwitch } from '../Fields';
import api from '../../api';
import { url_getLinesByLaboratory, url_getlaboraotriesByCountry, url_postLaboratoryUser } from '../../api/urls';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import ListAssignLaboratory from '../ListComponents/List/ListAssigLaboratory';
import { Button } from '@material-ui/core';
import { setLoad } from '../../redux/actions/context';
import { showError } from '../../redux/actions/error';

class LaboratoriesAssignModal extends Component{

    state={
        companies:[],
        laboratories:[],
    }

    handleChangeSelect = async (e) => {
      const {name,value}= e.target;
      try {
        this.props.setLoad(true);
        switch (name) {
          case 'country':{
            this.props.change('company', 'none');
            const response = await api.get(url_getlaboraotriesByCountry(value));
            this.setState({ companies: response.data, laboratories: []})
            break;
          }
          case 'company':{
            const response = await api.get(url_getLinesByLaboratory(value));
            const { user } = this.props;
            let data = response.data.reduce((acc, lab) =>{
              if(user.Laboratories.find(l => l.LaboratoryId === lab.Id) ? true : false){
                lab.Checked = true;
              }else{
                lab.Checked = false;
              }
              return [...acc, lab];

            }, []);
            this.setState({ laboratories: data });
            break;
          }
          default:{
            break;
          }
        }
      } catch (e) {
        this.props.showError(e);
      } finally {
        this.props.setLoad(false);
      }
    }

    handleChangeChecklaboratory =  (e)=>{
        const {name,checked}=e.target;
        let laboratories = this.state.laboratories.reduce((acc, lab) =>{
            if(lab.Id===Number(name)){
               lab.Checked = checked;
            }
            return [...acc, lab];
        },[]);
        this.setState({laboratories});

    }

    Save = async () =>{

        const {countries, countrySelected,allLabs}= this.props
        const country = countries.find(c => c.Codigo === countrySelected);
        const userId = this.props.user.Details.UserID;
        const laboratories = this.state.laboratories;
        try {
          this.props.setLoad(true);
          await api.post(url_postLaboratoryUser, {
            userId,
            laboratories,
            country,
            allLabs: allLabs === undefined ? false : allLabs
          });
          this.props.saveSuccess();
          this.props.change('country', undefined);
          this.props.change('country', 'none');
          this.setState({
            companies: [],
            laboratories: []
          });
        } catch (e) {
          this.props.showError(e);
        } finally {
          this.props.setLoad(false);
        }
    }

    render(){
        const {countries,toggle,open,countrySelected,companySelected, allLabs} = this.props;
        const {companies,laboratories} = this.state;
        return (
            <Modal fade={false} isOpen={open} toggle={() => toggle()}>
                <ModalHeader>Asignar laboratorios</ModalHeader>
                <ModalBody>
                    <Grid>
                        <Row>
                            <Col sm={12}>
                               <FieldSwitch icons={null} name='all' label='Asignar todos los laboratorios'  />
                            </Col>
                            <Col sm={12} hidden={allLabs}>
                                <FieldSelect name='country' label='País' onChange={this.handleChangeSelect}  items={countries} valueField='Codigo' textField='Nombre' />
                            </Col>
                            <Col sm={12}  hidden={allLabs}>
                                <FieldSelect name='company' label='Compañía' onChange={this.handleChangeSelect}  items={companies} valueField='Id' textField='Name' />
                            </Col>
                            <Col sm={12}  hidden={allLabs}>
                                <ListAssignLaboratory list={laboratories} handleAction={this.handleChangeChecklaboratory} />
                            </Col>
                        </Row>
                    </Grid>
                </ModalBody>
                <ModalFooter>
                        <Button type="button" className="card-actions-button button-back" onClick={()=>toggle()} variant="contained">Cancelar</Button>
                        <Button type="button" disabled={(!countrySelected && !companySelected && !allLabs) } className="card-actions-button button-next" onClick={this.Save} variant="contained">
                            Asignar
                        </Button>
                </ModalFooter>
            </Modal>
        );
    }
};

const validate = values =>{
    const error ={};
    return error;

}

const mapStateToProps = (state) =>({
    countrySelected: formValueSelector('LaboratoriesAssign')(state, 'country'),
    companySelected: formValueSelector('LaboratoriesAssign')(state, 'company'),
    allLabs: formValueSelector('LaboratoriesAssign')(state, 'all'),
});
const mapDispatchToProps = {
    setLoad,
    showError
}
export default connect(mapStateToProps, mapDispatchToProps)(reduxForm({form: 'LaboratoriesAssign', validate})(LaboratoriesAssignModal));
