import React from "react";
import PropTypes from "prop-types";
import { FieldLabel } from "../../../../Fields";
import Section from "../../../Section";
import { Row, Col } from "react-flexbox-grid";
import { questionsStep3 } from "../../../../../constants/steps";
import { getElementCodiguera } from "../../../../../codigueras";

const renderBody = (RAM, codiguera) => {
  const actionMed = getElementCodiguera("accionesMedicamentos", RAM.AccMed, codiguera);

  return (
  <div>
    <Row className="noRow m-t-md">
      <Col md={12}>
        <FieldLabel
          label={"Descripción de la reacción y del tratamiento administrado"}
          value={RAM.Description}
        />
      </Col>
    </Row>
    <Row className="noRow">
      <Col md={4}>
        <FieldLabel
          label={"Evolución del evento reportable"}
          value={RAM.Evolution}
        />
      </Col>
      <Col md={4}>
        <FieldLabel
          label={"Acción tomada con el medicamento"}
          value={actionMed}
        />
      </Col>
      { <Col md={4}>
        <FieldLabel
          label={"¿Requirió hospitalización?"}
          type="bool"
          value={RAM.HospitalizationRequired}
        />
      </Col> }
      <Col md={3}>
        <FieldLabel
          label={"Fecha de inicio del evento reportable"}
          type="date"
          value={RAM.DateStart}
        />
      </Col>
      <Col md={3}>
        <FieldLabel
          label={"Fecha de fin del evento reportable"}
          type="date"
          value={RAM.DateEnd}
        />
      </Col>
       <Col md={4}>
        <FieldLabel
          label={"Fecha de inicio de la hospitalización"}
          type="date"
          value={RAM.HospitalizationDateStart}
        />
      </Col>
      <Col md={4}>
        <FieldLabel
          label={"Fecha de fin de la hospitalización"}
          type="date"
          value={RAM.HospitalizationDateEnd}
        />
      </Col>
    </Row>

    <Row className="noRow">
      {questionsStep3.map(q => (
        <Col sm={6} key={q.name}>
          <FieldLabel
            inline={true}
            type="bool"
            label={q.label}
            value={RAM[q.name]}
          />
        </Col>
      ))}
    </Row>
    {RAM.DoseReduction && (
      <Row className="noRow">
        <Col md={4}>
          <FieldLabel
            label={"Concentración - Unidad"}
            value={
              RAM.Concentration
                ? `${RAM.Concentration} - ${RAM.Unit.Unit}`
                : "---"
            }
          />
        </Col>
        <Col md={4}>
          <FieldLabel
            label={"Frecuencia"}
            value={RAM.Frequency ? RAM.Frequency.Unit : "---"}
          />
        </Col>
        <Col md={4}>
          <FieldLabel
            label={"Cantidad - Unidad"}
            value={RAM.Cant ? `${RAM.Cant} - ${RAM.UnitUso.Unit}` : "---"}
          />
        </Col>
      </Row>
    )}
    {RAM.AnotherCondition && (
      <Row className="noRow">
        <Col md={12}>
          <FieldLabel
            label={
              "Ingrese otras consecuencias que considere clínicamente significativas para la reacción adversa reportada"
            }
            value={RAM.Considerations}
          />
        </Col>
      </Row>
    )}
  </div>
);}

const AdverseReactionsDetail = ({
  RAM,
  handleBtnEdit,
  step,
  title,
  codiguera
}) => {
  return (
    <Section title={title} edit={handleBtnEdit} step={step}>
      {RAM && renderBody(RAM, codiguera)}
    </Section>
  );
};

AdverseReactionsDetail.propTypes = {
  RAM: PropTypes.object
};

export default AdverseReactionsDetail;
